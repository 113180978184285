import React from 'react';
import { Container, Typography, Box, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const Benefits = () => {
  return (
    <Container>
      {/* Benefits Section */}
      <Box
        sx={{
          backgroundColor: '#f9f9f9',
          padding: '40px',
          borderRadius: '8px',
          textAlign: 'left',
          boxShadow: '0 4px 12px rgba(0,0,0,0.1)',
          margin: '40px 0'
        }}
      >
        <Typography variant="h4" sx={{ fontWeight: 'bold', marginBottom: '20px' }}>
          Made for Event Professionals
        </Typography>
        <Typography paragraph>
          Elevate Event Studio is designed to streamline media collection for event professionals, offering professional tools that save time, reduce costs, and enhance services.
        </Typography>
        
        <List>
          <ListItem>
            <ListItemIcon>
              <CheckCircleIcon sx={{ color: 'green' }} />
            </ListItemIcon>
            <ListItemText primary="Create customized experiences that turn your events into something unforgettable." />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <CheckCircleIcon sx={{ color: 'green' }} />
            </ListItemIcon>
            <ListItemText primary="Offer a service to stand out against competitors" />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <CheckCircleIcon sx={{ color: 'green' }} />
            </ListItemIcon>
            <ListItemText primary="Increase client satisfaction and your profit margins." />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <CheckCircleIcon sx={{ color: 'green' }} />
            </ListItemIcon>
            <ListItemText primary="Collect photos and videos effortlessly, no logins required." />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <CheckCircleIcon sx={{ color: 'green' }} />
            </ListItemIcon>
            <ListItemText primary="Maximize revenue with professional media collection." />
          </ListItem>
        </List>
      </Box>
    </Container>
  );
};

export default Benefits;
