import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, useNavigate, Link } from 'react-router-dom';
import { AppBar, Toolbar, IconButton, Menu, MenuItem, Box, Button, CircularProgress, Drawer, List, ListItem, ListItemText, Divider, Typography } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Home from './routes/Home';
import EventCreationForm from './components/EventCreationForm';
import EventPage from './components/EventPage';
import SignUp from './components/SignUp';
import SignIn from './components/SignIn';
import LogOut from './components/LogOut';
import FAQ from './components/FAQ';
import Subpage from './components/Subpage';
import PrivateRoute from './components/PrivateRoute';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { getFirestore, doc, getDoc } from 'firebase/firestore';
import { getFunctions, httpsCallable } from 'firebase/functions';
import './App.css';
import TermsAndConditions from './components/TermsAndConditions';
import EventDashboard from './components/EventDashboard';
import PrivatePolicy from './components/PrivatePolicy';
import QRCodeTemplates from './components/QRCodeTemplates';
import Docs from './components/Docs';
import ROICalculator from './components/ROICalculator';
import Sidebar from './components/Sidebar';
import logo from './assets/logo.svg';

function App() {
  return (
    <Router>
      <AppWrapper />
    </Router>
  );
}

const AppWrapper = () => {
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [hasSubscription, setHasSubscription] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [resourcesAnchorEl, setResourcesAnchorEl] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      setLoading(true);
      if (user) {
        setCurrentUser(user);
        const db = getFirestore();
        const userDocRef = doc(db, 'users', user.uid);
        const userDoc = await getDoc(userDocRef);
        setHasSubscription(userDoc.exists() && userDoc.data().subscriptionStatus === 'active');
      } else {
        setCurrentUser(null);
        setHasSubscription(false);
      }
      setLoading(false);
    });
    return () => unsubscribe();
  }, []);

  const sendToCustomerPortal = async () => {
    try {
      const functions = getFunctions();
      const createPortalLink = httpsCallable(functions, 'ext-firestore-stripe-payments-createPortalLink');
      const { data } = await createPortalLink({ returnUrl: window.location.origin });
      window.location.assign(data.url);
    } catch (error) {
      console.error('Error redirecting to Stripe customer portal:', error);
    }
  };

  const handleLogout = () => {
    const auth = getAuth();
    auth.signOut()
      .then(() => {
        console.log('User signed out');
        navigate('/', { replace: true });
      })
      .catch((error) => console.error('Error signing out:', error));
  };

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setResourcesAnchorEl(null);
  };

  const handleResourcesClick = (event) => {
    setResourcesAnchorEl(event.currentTarget);
  };

  const toggleDrawer = (open) => () => {
    setDrawerOpen(open);
  };

  if (loading) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
        }}
      >
        <CircularProgress size={50} />
      </Box>
    );
  }

  return (
    <>
      {currentUser && <Sidebar isAuthenticated={!!currentUser} />}

      <AppBar
  position="sticky"
  sx={{
    backgroundColor: 'white',
    color: 'black',
    height: { xs: '80px', md: '140px' },
    borderBottom: '1px solid #ddd', // Add a light line
    boxShadow: 'none', // Remove shadow
  }}
>
  <Toolbar sx={{ minHeight: { xs: '80px', md: '140px' } }}>
    <IconButton edge="start" color="inherit" aria-label="logo" href="/">
      <Box
        component="img"
        src={logo}
        alt="Elevate Event Studio Logo"
        sx={{ height: { xs: '100px', md: '150px' }, marginRight: '10px' }}
      />
    </IconButton>

    <Box sx={{ flexGrow: 1 }} />

    {/* Resources Dropdown */}
    <Box sx={{ display: { xs: 'none', md: 'flex' }, gap: 2 }}>
      <Button
        color="inherit"
        endIcon={<ArrowDropDownIcon />}
        sx={{ fontSize: '18px' }}
        onClick={handleResourcesClick}
      >
        Resources
      </Button>
      <Menu anchorEl={resourcesAnchorEl} open={Boolean(resourcesAnchorEl)} onClose={handleMenuClose}>
        <MenuItem onClick={() => { handleMenuClose(); navigate('/faq'); }}>FAQ</MenuItem>
        <MenuItem onClick={() => { handleMenuClose(); navigate('/resources'); }}>ROI Calculator</MenuItem>
      </Menu>
    </Box>

    {/* Sign Up / Sign In or Account Menu */}
    {!currentUser ? (
      <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
        <Button component={Link} to="/signup" color="inherit" sx={{ fontSize: '18px', marginRight: '10px' }}>Sign Up</Button>
        <Button component={Link} to="/signin" color="inherit" sx={{ fontSize: '18px' }}>Sign In</Button>
      </Box>
    ) : (
      <>
        <IconButton color="inherit" onClick={handleMenuOpen}>
          <AccountCircleIcon />
        </IconButton>
        <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose}>
          <MenuItem onClick={() => { handleMenuClose(); sendToCustomerPortal(); }}>Manage Account</MenuItem>
          <MenuItem onClick={handleLogout}>Log Out</MenuItem>
        </Menu>
      </>
    )}

    {/* Mobile Menu Icon */}
    <IconButton color="inherit" edge="end" onClick={toggleDrawer(true)} sx={{ display: { xs: 'block', md: 'none' } }}>
      <MenuIcon />
    </IconButton>

    {/* Drawer for Mobile View */}
    <Drawer anchor="right" open={drawerOpen} onClose={toggleDrawer(false)}>
      <Box sx={{ width: 250 }} role="presentation" onClick={toggleDrawer(false)} onKeyDown={toggleDrawer(false)}>
        <List>
          <ListItem button component={Link} to="/">
            <ListItemText primary="Home" />
          </ListItem>
          <ListItem button component={Link} to="/faq">
            <ListItemText primary="FAQ" />
          </ListItem>
          <ListItem button component={Link} to="/resources">
            <ListItemText primary="ROI Calculator" />
          </ListItem>
          <Divider />
          {!currentUser ? (
            <>
              <ListItem button component={Link} to="/signup">
                <ListItemText primary="Sign Up" />
              </ListItem>
              <ListItem button component={Link} to="/signin">
                <ListItemText primary="Sign In" />
              </ListItem>
            </>
          ) : (
            <>
              {hasSubscription && (
                <ListItem button component={Link} to="/create-event">
                  <ListItemText primary="Create Event" />
                </ListItem>
              )}
              <ListItem button onClick={sendToCustomerPortal}>
                <ListItemText primary="Manage Account" />
              </ListItem>
              <ListItem button onClick={handleLogout}>
                <ListItemText primary="Log Out" />
              </ListItem>
            </>
          )}
        </List>
      </Box>
    </Drawer>
  </Toolbar>
</AppBar>

      <Box sx={{ width: '100%', marginTop: '20px' }}>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/signin" element={<SignIn />} />
          <Route path="/logout" element={<LogOut />} />
          <Route path="/faq" element={<FAQ />} />
          <Route path="/subpage" element={<Subpage />} />
          <Route path="/terms" element={<TermsAndConditions />} />
          <Route path="/create-event" element={<PrivateRoute element={EventCreationForm} />} />
          <Route path="/eventdashboard" element={<EventDashboard />} />
          <Route path="/events/:uniqueUrl" element={<EventPage />} />
          <Route path="/privacy-policy" element={<PrivatePolicy />} />
          <Route path="/qrcode-templates" element={<QRCodeTemplates />} />
          <Route path="/docs" element={<Docs />} />
          <Route path="/resources" element={<ROICalculator />} />
        </Routes>
      </Box>
    </>
  );
};

export default App;
