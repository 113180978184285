// src/services/mediaService.js

import { updateDoc, arrayRemove, doc } from "firebase/firestore";
import { getStorage, ref, deleteObject } from "firebase/storage";
import { db } from '../firebase/firebaseConfig'; // Import Firestore database

// Function to delete media from both Firebase Storage and Firestore
export const deleteMedia = async (uniqueUrl, mediaUrl, mediaType, userId, eventDataUserId) => {
  if (userId !== eventDataUserId) {
    throw new Error("Only the event planner can delete media.");
  }

  try {
    // Delete the media from Firebase Storage
    const storage = getStorage();
    const mediaRef = ref(storage, mediaUrl);
    await deleteObject(mediaRef);

    // Remove the media reference from Firestore
    const eventRef = doc(db, 'events', uniqueUrl);
    await updateDoc(eventRef, {
      [mediaType === 'photo' ? 'photos' : 'videos']: arrayRemove(mediaUrl)
    });

    return { success: true };
  } catch (error) {
    console.error('Error deleting media:', error);
    throw error;
  }
};
