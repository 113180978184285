import { db, storage } from './firebaseConfig';
import { doc, updateDoc, arrayUnion } from "firebase/firestore";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";

const uploadMedia = async (uniqueUrl, file) => {
  try {
    // Create a storage reference
    const storageRef = ref(storage, `events/${uniqueUrl}/${file.name}`);
    
    // Upload the file
    const snapshot = await uploadBytes(storageRef, file);
    
    // Get the download URL
    const mediaUrl = await getDownloadURL(snapshot.ref);
    
    // Determine file type (photo or video)
    const fileType = file.type.startsWith('video/') ? 'videos' : 'photos';
    
    // Update Firestore document with the new media URL
    const eventRef = doc(db, 'events', uniqueUrl);
    await updateDoc(eventRef, {
      [fileType]: arrayUnion(mediaUrl)
    });
    
    return mediaUrl;
  } catch (error) {
    console.error('Error uploading media:', error);
    throw error;
  }
};

export default uploadMedia;


