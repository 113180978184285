import React from 'react';

const PrivatePolicy = () => {
  return (
    <div>
      <h1>Privacy Policy for Elevate Event Studio</h1>
      <p><strong>Effective Date:</strong> 10/22/2024</p>

      <p>
        Elevate Event Studio (“we,” “us,” or “our”) is committed to protecting the privacy of individuals who use our services, 
        visit our website, or interact with our event planning tools (collectively, the “Service”). This Privacy Policy outlines how 
        we collect, use, disclose, and safeguard your information.
      </p>

      <h2>1. Information We Collect</h2>
      <p>We collect various types of information when you use our Service, including:</p>
      <ul>
        <li><strong>Personal Information:</strong> Information such as your name, email address, payment information, and any other details provided when signing up for an account or creating an event.</li>
        <li><strong>Event Data:</strong> Details related to events you create, including event names, dates, welcome messages, photos, and videos.</li>
        <li><strong>User-Generated Content:</strong> Media uploaded by event attendees, such as photos and videos.</li>
        <li><strong>Technical and Usage Data:</strong> Information automatically collected such as IP address, browser type, device information, and pages visited.</li>
      </ul>

      <h2>2. How We Use Your Information</h2>
      <p>We use the information we collect to:</p>
      <ul>
        <li>Provide and maintain the Service, including creating and managing events.</li>
        <li>Process payments and manage subscriptions.</li>
        <li>Facilitate media uploads and the creation of digital galleries.</li>
        <li>Communicate with you regarding event updates, new features, and support.</li>
        <li>Analyze usage trends to improve the Service.</li>
        <li>Ensure compliance with legal obligations and prevent misuse of our platform.</li>
      </ul>

      <h2>3. Sharing Your Information</h2>
      <p>We do not sell your personal information. We may share your information in the following ways:</p>
      <ul>
        <li><strong>Service Providers:</strong> We work with trusted third parties (such as Stripe for payments and Firebase for data storage) to help operate our Service.</li>
        <li><strong>Legal Compliance:</strong> We may disclose your information if required to do so by law, or in response to a legal request or court order.</li>
        <li><strong>Business Transfers:</strong> In the event of a merger, acquisition, or sale of assets, your information may be transferred as part of that transaction.</li>
      </ul>

      <h2>4. Data Security</h2>
      <p>
        We are committed to protecting your data. We implement appropriate technical and organizational security measures to prevent 
        unauthorized access, disclosure, or loss of your data. However, no system can be 100% secure, so we cannot guarantee absolute security.
      </p>

      <h2>5. Cookies and Tracking Technologies</h2>
      <p>
        We use cookies and similar technologies to enhance your experience, track usage, and deliver personalized content. 
        You can control your cookie preferences through your browser settings.
      </p>

      <h2>6. Your Data Rights</h2>
      <p>You may have certain rights related to your personal information, including:</p>
      <ul>
        <li>The right to access, update, or delete your information.</li>
        <li>The right to object to or restrict certain types of data processing.</li>
        <li>The right to withdraw consent where applicable.</li>
      </ul>
      <p>To exercise these rights, please contact us at [Insert Contact Email].</p>

      <h2>7. Retention of Information</h2>
      <p>
        We retain your information for as long as necessary to fulfill the purposes outlined in this Privacy Policy or to comply with 
        legal obligations. You may request that we delete your data at any time.
      </p>

      <h2>8. Children’s Privacy</h2>
      <p>
        Elevate Event Studio is not intended for children under the age of 13. We do not knowingly collect personal information from children 
        under 13. If we discover that we have inadvertently collected such information, we will delete it.
      </p>

      <h2>9. Changes to This Privacy Policy</h2>
      <p>
        We may update this Privacy Policy periodically. We will notify you of significant changes by posting the revised policy on this page 
        with the updated effective date.
      </p>

      <h2>10. Contact Us</h2>
      <p>
        If you have any questions or concerns about this Privacy Policy or your personal data, please contact us at [Insert Contact Email].
      </p>
    </div>
  );
};

export default PrivatePolicy;
