import React, { useState, useRef } from 'react';
import { Button, Container, Typography } from '@mui/material';
import QRCode from 'qrcode.react';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { v4 as uuidv4 } from 'uuid';
import { getAuth } from 'firebase/auth';
import { doc, setDoc, getDoc } from 'firebase/firestore';
import { db } from '../firebase/firebaseConfig';

const EventPreviewForm = ({ eventDetails, customizationData }) => {
  const [qrCode, setQrCode] = useState('');
  const [eventUrl, setEventUrl] = useState('');
  const qrRef = useRef();

  // Generate a unique URL and QR code
  const handleCreateEvent = async () => {
    const auth = getAuth();
    const user = auth.currentUser;

    if (!user) {
      alert('User not authenticated');
      return;
    }

    const userId = user.uid;
    const shortUuid = uuidv4().replace(/-/g, '').slice(0, 12);
    const cleanedEventName = eventDetails.eventName.toLowerCase().replace(/\s+/g, '-').replace(/[^a-z0-9-]+/g, '');
    const fullPathUrl = `${cleanedEventName}-${shortUuid}`;
    const eventFullUrl = `${window.location.origin}/events/${fullPathUrl}`;

    const userRef = doc(db, 'users', userId);
    const userDoc = await getDoc(userRef);
    const currentEventCount = userDoc.data().eventCount || 0;

    const eventLimit = customizationData.planLimit || 3;  // Example: Default limit if not set

    if (currentEventCount >= eventLimit) {
      alert('Event creation limit reached');
      return;
    }

    const formData = {
      ...eventDetails,
      ...customizationData,
      uniqueUrl: fullPathUrl,
      userId,
      coverPhotoUrl: customizationData.coverPhotoUrl,  // Make sure cover photo is included
    };

    try {
      // Create the event and increment event count
      const eventRef = doc(db, 'events', fullPathUrl);
      await setDoc(eventRef, formData);

      await setDoc(userRef, { eventCount: currentEventCount + 1 }, { merge: true });

      // Set the QR code and event URL for preview
      setQrCode(eventFullUrl);
      setEventUrl(eventFullUrl);

    } catch (error) {
      console.error('Error creating event:', error);
    }
  };

  const downloadQRCodeAsImage = async () => {
    const canvas = await html2canvas(qrRef.current);
    const link = document.createElement('a');
    link.download = `${eventDetails.eventName}-QRCode.png`;
    link.href = canvas.toDataURL('image/png');
    link.click();
  };

  const downloadQRCodeAsPDF = async () => {
    const canvas = await html2canvas(qrRef.current);
    const pdf = new jsPDF();
    const imgData = canvas.toDataURL('image/png');
    pdf.addImage(imgData, 'PNG', 10, 10);
    pdf.save(`${eventDetails.eventName}-QRCode.pdf`);
  };

  const shareEvent = () => {
    if (navigator.share) {
      navigator.share({
        title: 'Event QR Code',
        text: 'Scan this QR code to access the event',
        url: eventUrl,
      }).catch((error) => console.error('Error sharing', error));
    } else {
      alert('Sharing not supported on this browser');
    }
  };

  return (
    <Container maxWidth="sm">
      <Typography variant="h4" component="h1" gutterBottom>
        Event Preview
      </Typography>

      {/* Button to create the event and generate the QR code */}
      <Button variant="contained" color="primary" onClick={handleCreateEvent}>
        Create Event
      </Button>

      {/* Display the QR code and event URL after event is created */}
      {qrCode && (
        <div style={{ marginTop: '20px', textAlign: 'center' }}>
          <Typography variant="h5" component="h2" gutterBottom>
            Scan QR Code
          </Typography>
          <div ref={qrRef} style={{ display: 'inline-block' }}>
            <QRCode value={qrCode} />
          </div>
          <Typography
            variant="body1"
            style={{ marginTop: '10px' }}
          >
            {eventUrl}
          </Typography>

          {/* Download and Share Buttons */}
          <Button variant="contained" color="secondary" onClick={downloadQRCodeAsImage} style={{ margin: '10px' }}>
            Download as Image
          </Button>
          <Button variant="contained" color="secondary" onClick={downloadQRCodeAsPDF} style={{ margin: '10px' }}>
            Download as PDF
          </Button>
          <Button variant="contained" color="secondary" onClick={shareEvent} style={{ margin: '10px' }}>
            Share Event
          </Button>
        </div>
      )}
    </Container>
  );
};

export default EventPreviewForm;
