import React, { useState } from 'react';
import { Container, Paper, Typography, Box, Button } from '@mui/material';
import EventDetailsForm from './EventDetailsForm';
import EventCustomizationForm from './EventCustomizationForm';
import EventPreviewForm from './EventPreviewForm';

const EventCreationForm = () => {
  const [step, setStep] = useState(1);
  const [eventDetails, setEventDetails] = useState({});
  const [customizationData, setCustomizationData] = useState({});

  const handleNextFromStep1 = (details) => {
    setEventDetails(details);
    setStep(2);
  };

  const handleNextFromStep2 = (customization) => {
    setCustomizationData(customization);
    setStep(3);
  };

  const handleBackToStep1 = () => setStep(1);
  const handleBackToStep2 = () => setStep(2);

  return (
    <Container maxWidth="md" sx={{ mt: 5, mb: 5 }}>
      <Paper elevation={3} sx={{ p: 4, borderRadius: 4, backgroundColor: '#f9f9f9' }}>
        <Typography
          variant="h4"
          component="h1"
          sx={{ fontWeight: 'bold', mb: 3, textAlign: 'center', color: '#333' }}
        >
          {step === 1
            ? 'Create Event - Step 1'
            : step === 2
            ? 'Customize Event - Step 2'
            : 'Event Preview - Step 3'}
        </Typography>

        {/* Step Forms */}
        {step === 1 && <EventDetailsForm onNext={handleNextFromStep1} />}
        {step === 2 && (
          <EventCustomizationForm
            eventDetails={eventDetails}
            onNext={handleNextFromStep2}
            onBack={handleBackToStep1}
          />
        )}
        {step === 3 && (
          <EventPreviewForm
            eventDetails={eventDetails}
            customizationData={customizationData}
            onBack={handleBackToStep2}
          />
        )}

        {/* Navigation Buttons */}
        {/* Navigation Buttons */}
{step > 1 && step !== 2 && (
  <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 3 }}>
    <Button
      variant="outlined"
      onClick={step === 3 ? handleBackToStep2 : handleBackToStep1}
      sx={{
        padding: '12px 24px',
        fontSize: '1rem',
        borderColor: '#FF6F61',
        color: '#FF6F61',
        '&:hover': { borderColor: '#FF5B4E', color: '#FF5B4E' },
      }}
    >
      Back
    </Button>
    {step !== 3 && (
      <Button
        variant="contained"
        sx={{
          backgroundColor: '#FF6F61', // Updated to pink color
          color: '#fff',
          padding: '12px 24px',
          fontSize: '1rem',
          fontWeight: 'bold',
          '&:hover': { backgroundColor: '#FF5B4E' }, // Hover color
        }}
      >
        Next
      </Button>
    )}
  </Box>
)}

      </Paper>
    </Container>
  );
};

export default EventCreationForm;