import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";  // Add this line
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { getFunctions, httpsCallable } from "firebase/functions";  // Firebase functions

const firebaseConfig = {
  apiKey: "AIzaSyDRFIJWJ4crpqWBU2VWFrIAUfPO6L60E7g",
  authDomain: "scan-cap.firebaseapp.com",
  projectId: "scan-cap",
  storageBucket: "scan-cap.appspot.com",
  messagingSenderId: "905360070878",
  appId: "1:905360070878:web:b900da590644067cdb8a4f",
  measurementId: "G-6NBYTPK4HJ"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const storage = getStorage(app);
const auth = getAuth(app);  // Initialize auth
const functions = getFunctions(app); 

export { app, db, storage, auth, functions };  // Export auth
