import React, { useState } from 'react';
import { Button, Container, Typography, IconButton, Grid, Input, Slider, Box, Card, CardMedia, Select, MenuItem } from '@mui/material'; // Added Select and MenuItem
import { PhotoCamera, ColorLens } from '@mui/icons-material';
import { fonts, themes } from '../utils/fontsandthemes'; // Removed photoGridStyles import
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage'; // Import Firebase storage

const EventCustomizationForm = ({ eventDetails, onNext, onBack }) => {
  const [theme, setTheme] = useState(themes[0].name); // Default to first theme
  const [titleFont, setTitleFont] = useState(fonts[0].name); // Default to first font
  const [titleFontSize, setTitleFontSize] = useState('20px'); // Default font size
  const [titleFontColor, setTitleFontColor] = useState('#000000'); // Default font color

  const [dateFont, setDateFont] = useState(fonts[0].name);
  const [dateFontSize, setDateFontSize] = useState('14px');
  const [dateFontColor, setDateFontColor] = useState('#000000');

  const [welcomeFont, setWelcomeFont] = useState(fonts[0].name);
  const [welcomeFontSize, setWelcomeFontSize] = useState('16px');
  const [welcomeFontColor, setWelcomeFontColor] = useState('#000000');

  const [buttonColor, setButtonColor] = useState('#1976d2'); // Default button color (Material-UI primary blue)
  const [coverPhoto, setCoverPhoto] = useState(null);
  const [coverPhotoUrl, setCoverPhotoUrl] = useState('https://via.placeholder.com/150'); // Placeholder image

  const placeholderImages = [
    'https://via.placeholder.com/150?text=Photo+1',
    'https://via.placeholder.com/150?text=Photo+2',
  
  ];

  // Font Color Pickers
  const handleFontColorChange = (e, type) => {
    const color = e.target.value;
    if (type === 'title') setTitleFontColor(color);
    if (type === 'date') setDateFontColor(color);
    if (type === 'welcome') setWelcomeFontColor(color);
  };

  // Cover Photo Logic
  const handleCoverPhotoChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      setCoverPhoto(file);
      const storage = getStorage(); // Initialize Firebase Storage
      const storageRef = ref(storage, `coverPhotos/${file.name}`);
      await uploadBytes(storageRef, file);
      const url = await getDownloadURL(storageRef);
      setCoverPhotoUrl(url);
    }
  };

  const handleNext = () => {
    const customizationData = { 
      theme, 
      titleFont, titleFontSize, titleFontColor, 
      dateFont, dateFontSize, dateFontColor, 
      welcomeFont, welcomeFontSize, welcomeFontColor,
      buttonColor,
      coverPhotoUrl,
    };
    onNext(customizationData);
  };

  // Render photo grid preview (now vertical)

const renderPhotoGridPreview = () => (
  <Grid container spacing={2} justifyContent="center">
    {placeholderImages.slice(0, 2).map((image, idx) => ( // Only display the first two images
      <Grid item xs={12} key={idx}> {/* Stack two images vertically */}
        <Card>
          <CardMedia component="img" image={image} alt={`Placeholder ${idx}`} />
        </Card>
      </Grid>
    ))}
  </Grid>
);


  return (
    <Container maxWidth="lg">
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          {/* Upload Cover Photo - Moved to Top */} 
          <Typography variant="body1">Upload Cover Photo:</Typography>
          <input
            accept="image/*"
            style={{ display: 'none' }}
            id="upload-cover-photo"
            type="file"
            onChange={handleCoverPhotoChange}
          />
          <label htmlFor="upload-cover-photo">
            <IconButton color="primary" aria-label="upload picture" component="span">
              <PhotoCamera />
            </IconButton>
          </label>

          {/* Theme Selector */}
          <Typography variant="body1">Choose Theme:</Typography>
          <Select value={theme} onChange={(e) => setTheme(e.target.value)} fullWidth>
            {themes.map((themeOption, index) => (
              <MenuItem key={index} value={themeOption.name}>
                {themeOption.name}
              </MenuItem>
            ))}
          </Select>

          {/* Title Font Selector */}
          <Typography variant="body1" style={{ marginTop: '10px' }}>Choose Title Font:</Typography>
          <Select value={titleFont} onChange={(e) => setTitleFont(e.target.value)} fullWidth>
            {fonts.map((fontOption, index) => (
              <MenuItem key={index} value={fontOption.name}>
                {fontOption.name}
              </MenuItem>
            ))}
          </Select>

          {/* Title Font Size */}
          <Typography variant="body1" style={{ marginTop: '10px' }}>Choose Title Font Size:</Typography>
          <Slider 
            value={parseInt(titleFontSize)} 
            onChange={(e, newValue) => setTitleFontSize(`${newValue}px`)} 
            aria-labelledby="font-size-slider" 
            valueLabelDisplay="auto" 
            min={12} max={36}
          />

          {/* Title Font Color */}
          <Typography variant="body1" style={{ marginTop: '10px' }}>Choose Title Font Color:</Typography>
          <IconButton component="label" color="primary">
            <ColorLens />
            <Input
              type="color"
              value={titleFontColor}
              onChange={(e) => handleFontColorChange(e, 'title')}
              style={{ display: 'none' }} // Hide input, use icon instead
            />
          </IconButton>

          {/* Date Font Selector */}
          <Typography variant="body1" style={{ marginTop: '10px' }}>Choose Date Font:</Typography>
          <Select value={dateFont} onChange={(e) => setDateFont(e.target.value)} fullWidth>
            {fonts.map((fontOption, index) => (
              <MenuItem key={index} value={fontOption.name}>
                {fontOption.name}
              </MenuItem>
            ))}
          </Select>

          {/* Date Font Size */}
          <Typography variant="body1" style={{ marginTop: '10px' }}>Choose Date Font Size:</Typography>
          <Slider 
            value={parseInt(dateFontSize)} 
            onChange={(e, newValue) => setDateFontSize(`${newValue}px`)} 
            aria-labelledby="font-size-slider" 
            valueLabelDisplay="auto" 
            min={12} max={36}
          />

          {/* Date Font Color */}
          <Typography variant="body1" style={{ marginTop: '10px' }}>Choose Date Font Color:</Typography>
          <IconButton component="label" color="primary">
            <ColorLens />
            <Input
              type="color"
              value={dateFontColor}
              onChange={(e) => handleFontColorChange(e, 'date')}
              style={{ display: 'none' }} // Hide input, use icon instead
            />
          </IconButton>

          {/* Welcome Message Font Selector */}
          <Typography variant="body1" style={{ marginTop: '10px' }}>Choose Welcome Message Font:</Typography>
          <Select value={welcomeFont} onChange={(e) => setWelcomeFont(e.target.value)} fullWidth>
            {fonts.map((fontOption, index) => (
              <MenuItem key={index} value={fontOption.name}>
                {fontOption.name}
              </MenuItem>
            ))}
          </Select>

          {/* Welcome Message Font Size */}
          <Typography variant="body1" style={{ marginTop: '10px' }}>Choose Welcome Message Font Size:</Typography>
          <Slider 
            value={parseInt(welcomeFontSize)} 
            onChange={(e, newValue) => setWelcomeFontSize(`${newValue}px`)} 
            aria-labelledby="font-size-slider" 
            valueLabelDisplay="auto" 
            min={12} max={36}
          />

          {/* Welcome Message Font Color */}
          <Typography variant="body1" style={{ marginTop: '10px' }}>Choose Welcome Message Font Color:</Typography>
          <IconButton component="label" color="primary">
            <ColorLens />
            <Input
              type="color"
              value={welcomeFontColor}
              onChange={(e) => handleFontColorChange(e, 'welcome')}
              style={{ display: 'none' }} // Hide input, use icon instead
            />
          </IconButton>

          {/* Button Color */}
         {/* Button Color with Color Picker */}
<Typography variant="body1" style={{ marginTop: '10px' }}>Choose Button Color:</Typography>
<IconButton component="label" color="primary">
  <ColorLens />
  <Input
    type="color"
    value={buttonColor}
    onChange={(e) => setButtonColor(e.target.value)}
    style={{ display: 'none' }} // Hide the input, icon will trigger it
  />
</IconButton>

        </Grid>

        {/* Preview Section with iPhone Frame */}
        <Grid item xs={12} md={6}>
          <Typography variant="h5" component="h2" gutterBottom>
            Event Preview
          </Typography>
          <Box
            sx={{
              width: 375,
              height: 812,
              border: '16px solid #000', // Changed to black iPhone frame
              borderRadius: '36px',
              boxShadow: '0 4px 12px rgba(0, 0, 0, 0.2)',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              margin: 'auto',
              background: '#ffffff',
              position: 'relative',
              overflow: 'hidden',
              flexDirection: 'column',
              padding: '0px',
            }}
          >
            {/* iPhone Top Notch */}
            <Box
              sx={{
                width: '60px',
                height: '5px',
                backgroundColor: '#c0c0c0',
                borderRadius: '10px',
                position: 'absolute',
                top: '18px',
              }}
            />
            {/* iPhone Camera */}
            <Box
              sx={{
                width: '20px',
                height: '20px',
                borderRadius: '50%',
                border: '2px solid #c0c0c0',
                position: 'absolute',
                top: '10px',
                right: '95px',
                backgroundColor: '#fff',
              }}
            />
            {/* Real-Time View Container */}
            <Box
              sx={{
                width: '100%',
                height: '100%',
                overflow: 'auto',
                padding: '20px',
                paddingTop: '80px', // Adjust for notch
                background: themes.find(t => t.name === theme).backgroundColor,
              }}
            >
              {/* Circular Cover Photo */}
              <div style={{ marginBottom: '20px', display: 'flex', justifyContent: 'center' }}>
                <img
                  src={coverPhotoUrl}
                  alt="Cover"
                  style={{
                    borderRadius: '50%',
                    width: '120px',
                    height: '120px',
                    objectFit: 'cover',
                    border: '5px solid #e0e0e0',
                  }}
                />
              </div>

              {/* Event Title */}
              <Typography
                variant="h5"
                style={{
                  fontFamily: `${titleFont}, sans-serif`,
                  fontSize: titleFontSize,
                  color: titleFontColor,
                  textAlign: 'center',
                  marginBottom: '10px',
                }}
              >
                {eventDetails.eventName}
              </Typography>

              {/* Event Date */}
              <Typography
                variant="body2"
                style={{
                  fontFamily: `${dateFont}, sans-serif`,
                  fontSize: dateFontSize,
                  color: dateFontColor,
                  textAlign: 'center',
                  marginBottom: '10px',
                }}
              >
                {eventDetails.eventDate}
              </Typography>

              {/* Welcome Message */}
              {eventDetails.welcomeMessage && (
                <Typography
                  variant="body2"
                  style={{
                    fontFamily: `${welcomeFont}, sans-serif`,
                    fontSize: welcomeFontSize,
                    color: welcomeFontColor,
                    textAlign: 'center',
                    marginBottom: '20px',
                  }}
                >
                  {eventDetails.welcomeMessage}
                </Typography>
              )}

              {/* Upload Button in Preview */}
              <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                <Button
                  variant="contained"
                  style={{
                    backgroundColor: buttonColor,
                    marginBottom: '30px',
                    width: '80%',
                  }}
                >
                  Upload Photos/Videos
                </Button>
              </Box>

              {/* Render Preview of Placeholder Images (Now Vertical) */}
              <div style={{ marginTop: '20px', padding: '10px' }}>
                {renderPhotoGridPreview()}
              </div>
            </Box>
          </Box>
        </Grid>
      </Grid>

      {/* Navigation Buttons */}
      <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px' }}>
        <Button variant="outlined" onClick={onBack}>Back</Button>
        <Button variant="contained" color="primary" onClick={handleNext}>Next</Button>
      </div>
    </Container>
  );
};

export default EventCustomizationForm;
