import React, { useState } from 'react';
import { Drawer, List, ListItem, ListItemIcon, ListItemText, Typography, IconButton, Box } from '@mui/material';
import { Home, QrCode, Description, Menu as MenuIcon } from '@mui/icons-material';
import { Link, useLocation } from 'react-router-dom';
import { useTheme, useMediaQuery } from '@mui/material';
import logo from '../assets/logo.svg';

const Sidebar = ({ isAuthenticated }) => {
  const [mobileOpen, setMobileOpen] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const location = useLocation();

  // Check if the current path is '/subpage' or the home page '/' and hide the sidebar if either
  if (location.pathname === '/subpage' || location.pathname === '/') {
    return null;
  }

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawerContent = (
    <div>
      <Typography
        variant="h5"
        component="h1"
        sx={{
          padding: '20px',
          fontWeight: 'bold',
          textAlign: 'center',
          backgroundColor: '#000000',
          color: '#FFFFFF',
        }}
      >
        Dashboard
      </Typography>

      <List>
        <ListItem button component={Link} to="/eventdashboard">
          <ListItemIcon>
            <Home />
          </ListItemIcon>
          <ListItemText primary="Home" />
        </ListItem>
        <ListItem button component={Link} to="/qrcode-templates">
          <ListItemIcon>
            <QrCode />
          </ListItemIcon>
          <ListItemText primary="QR Code Templates" />
        </ListItem>
        <ListItem button component={Link} to="/docs">
          <ListItemIcon>
            <Description />
          </ListItemIcon>
          <ListItemText primary="Docs" />
        </ListItem>
      </List>
    </div>
  );

  if (!isAuthenticated) {
    return null;
  }

  return (
    <>
      {isMobile ? (
        <>
          {/* Mobile View: Menu Icon and Logo */}
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', p: 1 }}>
            <IconButton
              color="inherit"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ position: 'absolute', top: 10, left: 10 }}
            >
              <MenuIcon />
            </IconButton>

            {/* Logo aligned to the right on mobile */}
            <Box
              component="img"
              src={logo}
              alt="Logo"
              sx={{
                height: '50px',
                position: 'absolute',
                top: 10,
                right: 10,
              }}
            />
          </Box>

          {/* Temporary Drawer for Mobile */}
          <Drawer
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{ keepMounted: true }} // Improves performance on mobile
            sx={{
              '& .MuiDrawer-paper': { boxSizing: 'border-box', width: 240 },
            }}
          >
            {drawerContent}
          </Drawer>
        </>
      ) : (
        // Desktop View: Permanent Sidebar
        <Drawer
          variant="permanent"
          sx={{
            width: 240,
            flexShrink: 0,
            '& .MuiDrawer-paper': { width: 240, boxSizing: 'border-box' },
          }}
        >
          {drawerContent}
        </Drawer>
      )}
    </>
  );
};

export default Sidebar;
