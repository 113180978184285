import React, { useState } from 'react';
import { TextField, Button, Container } from '@mui/material';

const EventDetailsForm = ({ onNext }) => {
  const [eventName, setEventName] = useState('');
  const [eventDate, setEventDate] = useState('');
  const [welcomeMessage, setWelcomeMessage] = useState('');

  const handleNext = () => {
    if (!eventName || !eventDate) {
      alert('Please provide event name and date.');
      return;
    }
    onNext({ eventName, eventDate, welcomeMessage });
  };

  return (
    <Container maxWidth="sm" sx={{ mt: 4 }}>
      {/* Event Name Input */}
      <TextField
        label="Event Name"
        value={eventName}
        onChange={(e) => setEventName(e.target.value)}
        fullWidth
        margin="normal"
        required
      />

      {/* Event Date Input */}
      <TextField
        label="Event Date"
        type="date"
        value={eventDate}
        onChange={(e) => setEventDate(e.target.value)}
        fullWidth
        margin="normal"
        InputLabelProps={{ shrink: true }}
        required
      />

      {/* Custom Greeting Input */}
      <TextField
        label="Custom Greeting"
        value={welcomeMessage}
        onChange={(e) => setWelcomeMessage(e.target.value)}
        fullWidth
        margin="normal"
        multiline
        rows={4}
        placeholder="Optional"
      />

      {/* Next Button */}
      <Button
        variant="contained"
        sx={{
          backgroundColor: '#000', // Black background color
          color: '#fff', // White text color
          mt: 3,
          padding: '12px 24px',
          fontWeight: 'bold',
          '&:hover': { backgroundColor: '#333' }, // Slightly lighter black on hover
        }}
        onClick={handleNext}
        fullWidth
      >
        Next
      </Button>
    </Container>
  );
};

export default EventDetailsForm;
