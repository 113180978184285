import React, { useEffect, useState } from 'react';
import { Container, Typography, Grid, Card, CardContent, CardActions, Button, IconButton, CardMedia, Box } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import { AddCircleOutline, Delete } from '@mui/icons-material'; // Icons for adding, editing, deleting events
import { db } from '../firebase/firebaseConfig';
import { collection, query, where, getDocs, deleteDoc, doc } from "firebase/firestore"; // Firestore functions
import dayjs from 'dayjs';
import { getAuth, onAuthStateChanged } from "firebase/auth"; // Firebase Authentication
import Sidebar from './Sidebar'; // Importing Sidebar

const EventDashboard = () => {
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState(null);
  const navigate = useNavigate();
  const auth = getAuth();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      if (currentUser) {
        setUser(currentUser);
        fetchEvents(currentUser.uid);
      } else {
        console.log('No user logged in');
        setLoading(false);
      }
    });

    return () => unsubscribe();
  }, [auth]);

  const fetchEvents = async (userId) => {
    try {
      const eventsRef = collection(db, 'events');
      const q = query(eventsRef, where('userId', '==', userId));
      const querySnapshot = await getDocs(q);
      const eventsList = querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));

      // Sort events by date
      eventsList.sort((a, b) => new Date(a.eventDate) - new Date(b.eventDate));
      setEvents(eventsList);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching events:', error);
      setLoading(false);
    }
  };

  const handleDelete = async (id) => {
    try {
      await deleteDoc(doc(db, 'events', id));
      setEvents(events.filter(event => event.id !== id));
      console.log('Event deleted successfully');
    } catch (error) {
      console.error('Error deleting event:', error);
    }
  };

  // Filter events by upcoming and completed
  const upcomingEvents = events.filter(event => dayjs(event.eventDate).isAfter(dayjs()));
  const completedEvents = events.filter(event => dayjs(event.eventDate).isBefore(dayjs()));

  if (loading) {
    return <div>Loading events...</div>;
  }

  return (
   <div style={{ display: 'flex', flexDirection: 'row' }}>
      {/* Sidebar */}
      <Sidebar /> 

      {/* Main Content */}
      <Container 
        sx={{ 
          padding: '20px', 
          background: 'linear-gradient(to bottom, #f0f4f8, #ffffff)', 
          boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)', 
          borderRadius: '12px', 
          marginTop: '20px',
          marginBottom: '20px', // Add some margin below for spacing
             width: 'calc(100% - 240px)',
          flexGrow: 1,
        }}
      >
        {/* Welcome Message for First-Time Users */}
        {upcomingEvents.length === 0 && completedEvents.length === 0 && (
          <Box sx={{ textAlign: 'center', marginBottom: '40px' }}>
            <Typography 
              variant="h4" 
              component="h1" 
              sx={{ 
                fontWeight: 'bold', 
                color: '#333',
                marginBottom: '10px' 
              }}
            >
              Welcome to Your Event Dashboard!
            </Typography>
            <Typography 
              variant="h6" 
              color="textSecondary" 
              sx={{ 
                marginBottom: '30px' 
              }}
            >
              Manage your events effortlessly, collect photos, and share memories with your clients. Get started by creating your first event.
            </Typography>
            <Button
              variant="contained"
              startIcon={<AddCircleOutline />}
              component={Link} 
              to="/create-event"
              sx={{
                marginBottom: '40px',
                backgroundColor: '#000000', // Black color for the button
                color: '#FFFFFF', // White text color
                fontWeight: 'bold',
                '&:hover': { backgroundColor: '#333333' }, // Slightly lighter black for hover state
                borderRadius: '8px',
                padding: '12px 24px',
                textTransform: 'none',
                boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)' // Subtle shadow to create depth
              }}
            >
              Create New Event
            </Button>
          </Box>
        )}

        {/* Main Title (Hidden when there are no events) */}
        {(upcomingEvents.length > 0 || completedEvents.length > 0) && (
          <>
            <Typography 
              variant="h4" 
              component="h1" 
              sx={{ 
                fontWeight: 'bold', 
                marginBottom: '30px',
              }}
            >
              Event Dashboard
            </Typography>

            {/* Create New Event Button (Hidden when there are no events) */}
            <Button
              variant="contained"
              startIcon={<AddCircleOutline />}
              component={Link} 
              to="/create-event"
              sx={{
                marginBottom: '40px',
                backgroundColor: '#000000', // Black button color
                color: '#FFFFFF', // White font color
                fontWeight: 'bold',
                '&:hover': { backgroundColor: '#333333' }, // Slightly lighter black on hover
                borderRadius: '8px',
                padding: '12px 24px',
                textTransform: 'none',
                boxShadow: 'none' // Remove default box shadow to keep it consistent
              }}
            >
              Create New Event
            </Button>
          </>
        )}

        {/* Upcoming Events Section */}
        <Typography 
          variant="h5" 
          component="h2" 
          sx={{ 
            fontWeight: '600', 
            marginBottom: '10px',
            textAlign: 'center' 
          }}
        >
          Upcoming Events
        </Typography>
        <Grid container spacing={3} justifyContent="center">
          {upcomingEvents.length > 0 ? (
            upcomingEvents.map((event) => (
              <Grid item xs={12} sm={12} md={12} key={event.id}>
                <Card 
                  sx={{
                    width: '350px', // Increased width of the card
                    height: '500px', // Increased height of the card
                    display: 'flex',
                    flexDirection: 'column',
                    borderRadius: '16px',
                    boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
                    transition: 'transform 0.3s ease, box-shadow 0.3s ease',
                    '&:hover': { transform: 'translateY(-5px)', boxShadow: '0 8px 16px rgba(0, 0, 0, 0.15)' },
                  }}
                >
                  {/* Cover Photo */}
                  <CardMedia
                    component="img"
                    height="240"  // Increased height to show more of the image
                    image={event.coverPhotoUrl}
                    alt={event.eventName}
                    sx={{ 
                      borderRadius: '16px 16px 0 0', 
                      objectFit: 'cover', 
                      objectPosition: '50% 30%' // Adjusted position to lower the image slightly
                    }}
                  />
                  <CardContent sx={{ padding: '24px' }}>
                    <Typography variant="h6" component="h3" sx={{ fontWeight: 'bold', marginBottom: '12px' }}>
                      {event.eventName}
                    </Typography>
                    <Typography variant="body2" color="textSecondary" sx={{ marginBottom: '8px' }}>
                      {dayjs(event.eventDate).format('MMMM D, YYYY')}
                    </Typography>
                  </CardContent>

                  <CardActions sx={{ paddingLeft: '24px', paddingRight: '24px', paddingBottom: '16px', justifyContent: 'space-between' }}>
                    <Button
                      size="small"
                      onClick={() => navigate(`/events/${event.uniqueUrl}`)}
                      sx={{ textTransform: 'none', fontSize: '14px', color: '#1e88e5', fontWeight: 'bold' }}
                    >
                      View Details
                    </Button>
                    <div>
                      <IconButton
                        onClick={() => handleDelete(event.id)}
                        sx={{ color: '#d32f2f' }}
                      >
                        <Delete fontSize="small" />
                      </IconButton>
                    </div>
                  </CardActions>
                </Card>
              </Grid>
            ))
          ) : (
            <Typography 
              variant="body1" 
              color="textSecondary" 
              sx={{ 
                display: 'flex', 
                justifyContent: 'center', 
                alignItems: 'center', 
                height: '200px', 
                textAlign: 'center'  
              }}
            >
              No upcoming events found.
            </Typography>
          )}
        </Grid>

        {/* Completed Events Section */}
        {completedEvents.length > 0 && (
          <>
            <Typography 
              variant="h5" 
              component="h2" 
              sx={{ 
                fontWeight: '600', 
                marginTop: '40px', 
                marginBottom: '10px' 
              }}
            >
              Completed Events
            </Typography>
            <Grid container spacing={3}>
              {completedEvents.map((event) => (
                <Grid item xs={12} sm={6} md={4} key={event.id}>
                  <Card 
                    sx={{
                      width: '350px', // Increased width of the card
                      height: '500px', // Increased height of the card
                      display: 'flex',
                      flexDirection: 'column',
                      borderRadius: '16px',
                      boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
                      transition: 'transform 0.3s ease, box-shadow 0.3s ease',
                      '&:hover': { transform: 'translateY(-5px)', boxShadow: '0 8px 16px rgba(0, 0, 0, 0.15)' },
                    }}
                  >
                    {/* Cover Photo */}
                    <CardMedia
                      component="img"
                      height="240"  // Increased height to show more of the image
                      image={event.coverPhotoUrl}
                      alt={event.eventName}
                      sx={{ 
                        borderRadius: '16px 16px 0 0', 
                        objectFit: 'cover', 
                        objectPosition: '50% 30%'  
                      }}
                    />
                    <CardContent sx={{ padding: '24px' }}>
                      <Typography variant="h6" component="h3" sx={{ fontWeight: 'bold', marginBottom: '12px' }}>
                        {event.eventName}
                      </Typography>
                      <Typography variant="body2" color="textSecondary" sx={{ marginBottom: '8px' }}>
                        {dayjs(event.eventDate).format('MMMM D, YYYY')}
                      </Typography>
                    </CardContent>

                    <CardActions sx={{ paddingLeft: '24px', paddingRight: '24px', paddingBottom: '16px', justifyContent: 'space-between' }}>
                      <Button
                        size="small"
                        onClick={() => navigate(`/events/${event.uniqueUrl}`)}
                        sx={{ textTransform: 'none', fontSize: '14px', color: '#1e88e5', fontWeight: 'bold' }}
                      >
                        View Details
                      </Button>
                      <div>
                        <IconButton
                          onClick={() => handleDelete(event.id)}
                          sx={{ color: '#d32f2f' }}
                        >
                          <Delete fontSize="small" />
                        </IconButton>
                      </div>
                    </CardActions>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </>
        )}
      </Container>
    </div>
  );
};

export default EventDashboard;
