// src/pages/Home.js
import React from 'react';
import { Link } from 'react-router-dom';
import { 
  Typography, 
  Button, 
  Box,   
  Container, 
  Grid 
} from '@mui/material';
import styled, { keyframes, css } from 'styled-components';
import QrCode2Icon from '@mui/icons-material/QrCode2';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import ImageIcon from '@mui/icons-material/Image';
import TouchAppIcon from '@mui/icons-material/TouchApp';
import BarChartIcon from '@mui/icons-material/BarChart';
import FeatureCard from '../components/FeatureCard';
import FAQ from '../components/FAQ';
import Benefits from '../components/Benefits';
import ChipList from '../components/ChipList';
import ROICalculator from '../components/ROICalculator';

import heroImage from '../assets/hero.jpg';

const Background = styled.div`
  width: 100vw;
  height: 100vh;
  background: #ffffff;
  position: fixed; 
  top: 0;
  left: 0;
  z-index: -1; 
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
`;

const HeroContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding-top: 0vh;
  padding-bottom: 5vh;
`;

const QRCodeIcon = styled(QrCode2Icon)`
  font-size: 70px;
  color: #4D4D4D;
  margin-bottom: 20px;
`;

const moveUpAndDown = keyframes`
  0% { transform: translateY(10px); }
  40% { transform: translateY(-60px); }
  60% { transform: translateY(-60px); }
  100% { transform: translateY(10px); }
`;

const PhoneIcon = styled(PhoneIphoneIcon)`
  font-size: 80px;
  color: #333;
  margin-top: 10px;
  animation: ${css`${moveUpAndDown}`} 3s ease-in-out infinite;
`;

const scatterAndConverge = keyframes`
  0% { opacity: 0; transform: translate(0, 0) scale(0.5); }
  25% { opacity: 1; transform: translate(-50px, -40px) scale(1); }
  50% { transform: translate(60px, -40px) scale(1); }
  75% { transform: translate(10px, 30px) scale(1); }
  95% { transform: translate(15px, 15px) scale(0.1); opacity: 1; }
  100% { opacity: 0; transform: translate(0, 0) scale(0.5); }
`;

const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
`;

const FloatingImageIcon = styled(ImageIcon)`
  position: absolute;
  font-size: 35px;
  color: #D3B58A;
  opacity: 0;
  animation: ${css`${scatterAndConverge}`} 3s ease-in-out infinite;
  border: 1px solid #D4AF37;
  border-radius: 5px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15);
  &:nth-child(1) { animation-delay: 0.5s; }
  &:nth-child(2) { animation-delay: 0.7s; }
  &:nth-child(3) { animation-delay: 0.9s; }
  &:nth-child(4) { animation-delay: 1.1s; }
`;

const EnhancedButton = styled(Button)`
  background: linear-gradient(135deg, #FFD700 0%, #D4AF37 100%) !important;
  color: #333 !important;
  border-radius: 8px !important; /* More squared edges */
  padding: 10px 50px !important; /* Thinner padding */
  width: 300px !important; /* Longer width */
  font-size: 20px !important;
  font-weight: 500 !important;
  box-shadow: 0px 8px 20px rgba(212, 175, 55, 0.3) !important;
  transition: all 0.3s ease !important;

  &:hover {
    background: linear-gradient(135deg, #D4AF37 0%, #B8860B 100%) !important;
    box-shadow: 0px 10px 30px rgba(212, 175, 55, 0.4) !important;
  }

  @media (max-width: 768px) { /* Hides button on mobile */
    display: none !important;
  }
`;

const MobileOverlayText = styled(Typography)`
  position: absolute;
  top: 40%;
  left: 0;
  right: 0;
  color: #ffffff; /* Fully white text for high contrast */
  font-size: 2.5rem;
  font-weight: bold;
  text-align: center;
  transform: translateY(-50%);
  text-shadow: 0px 0px 0px rgba(0, 0, 0, 0); /* Remove shadow if affecting transparency */
  font-family: "Futura", sans-serif;
`;

const MobileGradientOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0));
  z-index: 1;
`;

const MobileCTAButtonWrapper = styled(Link)`
  position: absolute;
  top: 55%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 60%;
  text-decoration: none;
  z-index: 2;
`;

const MobileCTAButton = styled(Button)`
  width: 100%;
  background-color: #ffffff !important;
  color: #000 !important;
  font-size: 1rem !important;
  padding: 8px 16px !important;
  border-radius: 15px !important;
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.2) !important;
`;

const Home = () => {
  return (
    <Box sx={{ width: '100%', margin: 0, padding: 0, overflowX: 'hidden' }}>
      <Background />

      <Container maxWidth={false} disableGutters sx={{ px: 0 }}>
        <Grid container spacing={0} alignItems="center">
          {/* Right side - Hero Image on top for mobile */}
          <Grid item xs={12} md={6} order={{ xs: 1, md: 2 }} sx={{ position: 'relative' }}>
            <Box
              component="img"
              src={heroImage}
              alt="Hero"
              sx={{
                width: '100%',
                height: { xs: '450px', sm: '600px', md: '1000px' },
                objectFit: 'cover',
                objectPosition: 'top',
                borderRadius: 0,
              }}
            />
            <MobileGradientOverlay />
            {/* Overlay text and white CTA button on mobile view */}
            <Box sx={{ display: { xs: 'block', md: 'none' }, textAlign: 'center', zIndex: 2 }}>
              <MobileOverlayText variant="h2">
                Elevate your events
              </MobileOverlayText>
              <MobileCTAButtonWrapper to="/signup">
                <MobileCTAButton>
                  Start Now
                </MobileCTAButton>
              </MobileCTAButtonWrapper>
            </Box>
          </Grid>

          {/* Left side - Content with animations */}
          <Grid item xs={12} md={6} order={{ xs: 2, md: 1 }}>
            <HeroContent>
              {/* Display the text only on desktop */}
              <Typography 
                variant="h2" 
                sx={{
                  display: { xs: 'none', md: 'block' },
                  fontFamily: `"Futura", sans-serif`,
                  fontWeight: '700',
                  fontSize: { md: '3.5rem' },
                  letterSpacing: '0.1em',
                  color: '#000',
                  lineHeight: 1.2,
                  textShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
                  marginBottom: '10px',
                }}
              >
               Effortlessly Create Live Event Galleries with Custom QR Codes
              </Typography>

              {/* Subtitle for mobile view, placed above the animation */}
              <Typography 
                variant="h6" 
                sx={{
                  display: { xs: 'block', md: 'none' },
                  fontFamily: `"Futura", sans-serif`,
                  fontWeight: { xs: 'bold', md: '300' }, // Bold for mobile view only
                  fontSize: '1.2rem',
                  letterSpacing: '0.05em',
                  color: '#000',
                  lineHeight: 1.8,
                  maxWidth: '700px',
                  margin: '20px auto',
                  textAlign: 'center',
                }}
              >
               Transform event photo collection with real-time, custom-branded QR code galleries.
              </Typography>

              <QRCodeIcon />
              <PhoneIcon />

              <ImageContainer>
                <FloatingImageIcon />
                <FloatingImageIcon />
                <FloatingImageIcon />
                <FloatingImageIcon />
              </ImageContainer>

              {/* Desktop-only subtitle and button */}
              <Typography 
                variant="h6" 
                sx={{
                  display: { xs: 'none', md: 'block' },
                  fontFamily: `"Futura", sans-serif`,
                  fontWeight: '300',
                  fontSize: '1.5rem',
                  letterSpacing: '0.05em',
                  color: '#000',
                  lineHeight: 1.8,
                  maxWidth: '700px',
                  margin: '20px auto',
                }}
              >
                Transform event photo collection with real-time, custom-branded QR code galleries.
              </Typography>

              <EnhancedButton component={Link} to="/signup">
                Start Now
              </EnhancedButton>
            </HeroContent>
          </Grid>
        </Grid>
      </Container>

      <Container maxWidth="lg" sx={{ mt: 10 }}>
        <ChipList />
      </Container>

      <Container maxWidth="lg" sx={{ mt: 5 }}>
        <Typography 
          variant="h4" 
          gutterBottom 
          sx={{ 
            fontFamily: `"Futura", sans-serif`, 
            fontWeight: '600', 
            letterSpacing: '0.5px', 
            color: '#000', 
            textAlign: 'center', 
            fontSize: '2.5rem',
            marginBottom: '20px' 
          }}
        >
          What sets Elevate Event Studio apart?
        </Typography>

        <Grid container spacing={4}>
          <Grid item xs={12} sm={6} md={4}>
            <FeatureCard
              title="Higher ROI"
              description="Increase your revenue by offering this premium service to your clients."
              icon={<BarChartIcon sx={{ fontSize: 55, color: '#000' }} />}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <FeatureCard
              title="Event Customization"
              description="Create online galleries with a personal touch that impresses clients."
              icon={<TouchAppIcon sx={{ fontSize: 55, color: '#000' }} />}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <FeatureCard
              title="Streamlined Media Collection"
              description="Instantly collect photos and videos with a simple QR code. No logins, no hassle!"
              icon={<PhoneIphoneIcon sx={{ fontSize: 55, color: '#000' }} />}
            />
          </Grid>
        </Grid>
      </Container>

      <Benefits />

      <Container maxWidth="lg" sx={{ mt: 8, textAlign: 'center' }}>
        <Typography 
          variant="h4" 
          gutterBottom 
          sx={{ 
            fontFamily: `"Futura", sans-serif`, 
            color: '#000',
            fontSize: '2.5rem',
          }}
        >
          Ready to Transform Your Events?
        </Typography>
        <EnhancedButton component={Link} to="/signup">
          Start Now
        </EnhancedButton>
      </Container>

      <Container maxWidth="lg" sx={{ mt: 8 }}>
        <Typography 
          variant="h4" 
          gutterBottom 
          sx={{ 
            fontFamily: `"Futura", sans-serif`, 
            color: '#000',
            fontSize: '2.5rem',
          }}
        >
          Frequently Asked Questions
        </Typography>
        <FAQ />
      </Container>

      <Container maxWidth="lg" sx={{ mt: 8, mb: 10 }}>
        <ROICalculator />
      </Container>

      <Box sx={{ textAlign: 'center', padding: '20px 0', backgroundColor: '#f0f0f0' }}>
        <Typography variant="body2" sx={{ fontFamily: `"Futura", sans-serif`, color: '#000', fontSize: '1.1rem' }}>
          <Link to="/privacy-policy" style={{ textDecoration: 'none', color: '#000' }}>
            Privacy Policy
          </Link>
        </Typography>
      </Box>
    </Box>
  );
};

export default Home;
