import React, { useState, useEffect } from 'react';
import { Container, Grid, Card, CardContent, Typography, Button, Box, CircularProgress } from '@mui/material';
import { auth } from '../firebase/firebaseConfig';
import { getApp } from 'firebase/app';
import { getFirestore, doc, addDoc, collection, onSnapshot } from 'firebase/firestore';
import { loadStripe } from '@stripe/stripe-js';

const plans = [
  { 
    name: 'Essential Plan', // Changed from Starter Plan
    price: '$295.00/year', 
    description: 'Up to 15 events, Free 1-month trial', 
    features: [
      'Up to 15 events',
      'Cover photo on event gallery',
      'Welcome message for guests',
      'Event title customization',
      'Seamless photo sharing via QR code',
      'Automated photo collection',
      '1-month free trial'
    ],
    priceId: 'price_1QKlDjRtiroMBcDIwgggoFGO',
    isFreeTrial: true
  },
  { 
    name: 'Advanced Plan', // Changed from Pro Plan
    price: '$445.00/year', 
    description: 'Up to 30 events, no Free trial', 
    features: [
      'Up to 30 events',
      'Cover photo on event gallery',
      'Welcome message for guests',
      'Event title customization',
      'Seamless photo sharing via QR code',
      'Automated photo collection'
    ],
    priceId: 'price_1QKlF1RtiroMBcDIcu7Z5SLz'
  },
  { 
    name: 'Enterprise Plan', 
    price: 'Contact us', 
    description: 'custom pricing available', 
    features: [
      'Cover photo on event gallery',
      'Welcome message for guests',
      'Event title customization',
      'Seamless photo sharing via QR code',
      'Automated photo collection'
    ],
    priceId: 'price_your_enterprise_plan_id'
  },
];

const Subpage = () => {
  const [userId, setUserId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        setUserId(user.uid);
      } else {
        setUserId(null);
      }
    });
    return () => unsubscribe();
  }, []);

  const handleCheckout = async (priceId) => {
    setLoading(true);
    setError(null);

    try {
      if (!userId) {
        throw new Error('User is not authenticated');
      }

      const app = getApp();
      const db = getFirestore(app);

      const userDocRef = doc(db, 'users', userId);

      const sessionData = {
        price: priceId,
        success_url: 'https://elevateevent.co/eventdashboard', // Updated success URL
        cancel_url: window.location.origin + '/cancel',
        allow_promotion_codes: true,  // This enables the promo code field
        // Any other fields you already have, like customer ID or metadata
      };

      if (priceId === 'price_1QKlDjRtiroMBcDIwgggoFGO') {
        sessionData.trial_period_days = 30;
      }

      const sessionRef = await addDoc(collection(userDocRef, 'checkout_sessions'), sessionData);

      const unsubscribe = onSnapshot(sessionRef, (doc) => {
        const data = doc.data();
        const stripeSessionId = data?.sessionId;

        if (stripeSessionId) {
          loadStripe('pk_live_51PYc2DRtiroMBcDILn1UgHqdZwqK8DWTpioBHvfAGvQOHrQ0B8f9v4J9aXpUcsLyqkKvQwiPbPo6N2bZTdR549Zg00Le2nYE3R')
            .then((stripe) => {
              if (stripe) {
                stripe.redirectToCheckout({ sessionId: stripeSessionId })
                  .then((result) => {
                    if (result.error) {
                      setError(result.error.message);
                    }
                  })
                  .catch((error) => {
                    setError(error.message);
                  })
                  .finally(() => setLoading(false));
              }
            })
            .catch((error) => {
              setError(error.message);
            });
        }
      });
    } catch (error) {
      setError(error.message);
      setLoading(false);
    }
  };

  return (
    <Container maxWidth="lg" sx={{ marginTop: 4, padding: { xs: 2, md: 4 } }}>
      <Typography 
        variant="h4" 
        component="h1" 
        align="center" 
        gutterBottom 
        sx={{ color: '#000', fontWeight: 'bold' }}  // Black for main heading
      >
        Choose Your Plan
      </Typography>
      <Typography 
        variant="h6" 
        align="center" 
        color="textSecondary" 
        gutterBottom 
        sx={{ color: '#666', fontSize: { xs: '0.9rem', md: '1.25rem' } }} // Lighter gray for subheading
      >
        Tailored for event planners – collect and share photos seamlessly with no attendee login required.
      </Typography>
      
      <Grid container spacing={3} alignItems="stretch" justifyContent="center">
        {plans.map((plan, index) => (
          <Grid 
            item 
            xs={12} 
            sm={6} 
            md={4} 
            key={index}
          >
<Card 
  sx={{
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: '24px',
    backgroundColor: '#fff', // White for all plans
    color: '#000', // Black text for all plans
    borderRadius: '12px',
    boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
    border: 'none', // No border for any plan
    position: 'relative',
    minHeight: '100%', 
    transition: 'transform 0.3s ease, box-shadow 0.3s ease',
    '&:hover': {
      transform: 'translateY(-5px)',
      boxShadow: '0 8px 16px rgba(0, 0, 0, 0.15)',
    },
  }}
>
  <CardContent>
    <Typography variant="h5" sx={{ 
        fontWeight: 'bold', 
        fontSize: '1.75rem', // Increased font size for title 
        marginBottom: '8px' // Add a bit of space below the title 
    }}>
      {plan.name}
    </Typography>
    <Typography variant="h6" sx={{ 
        marginBottom: '16px', 
        fontSize: '1.25rem', // Increased font size for the price 
        fontWeight: '500' // Medium weight to balance visibility 
    }}>
      {plan.price}
    </Typography>
    <Typography variant="body1" sx={{ 
        marginBottom: '20px', 
        fontSize: '1.1rem', // Slightly larger for description 
        color: '#555' // A subtle color for a more professional look
    }}>
      {plan.description}
    </Typography>
    <ul>
      {plan.features.map((feature, idx) => (
        <li key={idx}>
          <Typography variant="body2" sx={{ 
              fontSize: '1.05rem', // Slightly larger for features 
              marginBottom: '5px', // Space between features 
              color: '#333' // Darker color for features
          }}>
            {feature}
          </Typography>
        </li>
      ))}
    </ul>
  </CardContent>
  <Box textAlign="center" mt={2}>
    <Button
      variant="contained"
      sx={{
        backgroundColor: '#000', // Black button background
        color: '#fff', // White text
        padding: '14px 28px',
        fontWeight: 'bold',
        borderRadius: '8px',
        fontSize: '1rem',
        textTransform: 'uppercase',
        '&:hover': {
          backgroundColor: '#d4af37', // Gold hover
        }
      }}
      onClick={() => handleCheckout(plan.priceId)}
      disabled={!userId}
    >
      {plan.name === 'Essential Plan' ? 'Start Trial' : 'Select'}
    </Button>
  </Box>
</Card>
          </Grid>
        ))}
      </Grid>

      {loading && (
        <Box textAlign="center" mt={4}>
          <CircularProgress />
          <Typography variant="h6" align="center" gutterBottom>
            Redirecting to checkout...
          </Typography>
        </Box>
      )}
      {error && (
        <Box mt={2}>
          <Typography variant="h6" align="center" color="error">
            An error occurred: {error}
          </Typography>
        </Box>
      )}
    </Container>
  );
};

export default Subpage;
