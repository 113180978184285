import React from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import { useAuth } from '../firebase/auth';

const LogOut = () => {
  const { logOut } = useAuth(); // Get logOut from useAuth
  const navigate = useNavigate(); // Initialize useNavigate hook

  const handleLogOut = async () => {
    try {
      await logOut();
      alert('User signed out successfully!');
      navigate('/Home'); // Navigate to Home.js after logout
    } catch (error) {
      alert('Error signing out: ' + error.message);
    }
  };

  return <button onClick={handleLogOut}>Log Out</button>;
};

export default LogOut;
