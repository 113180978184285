import React from 'react';
import '../styles/TermsAndConditions.css';

const TermsAndConditions = () => {
  return (
    <div className="terms-container">
      <h1>Elevate Event Studio Terms and Conditions</h1>
      <p>Last updated: 10/7/2024</p>

      <section>
        <h2>1. Acceptance of Terms</h2>
        <p>
          By accessing or using Elevate Event Studio, you agree to comply with and be legally bound by the terms and conditions of these Terms, whether or not you become a registered user of our services. If you do not agree to these Terms, you may not use our services.
        </p>
      </section>

      <section>
        <h2>2. Description of Services</h2>
        <p>
          Elevate Event Studio provides event planners with a digital platform that enables their event attendees to upload photos and videos via QR codes into a centralized gallery. Event planners can customize QR codes for their events, which attendees can scan to access the media upload feature without requiring login credentials.
        </p>
      </section>

      <section>
        <h2>3. User Accounts and Registration</h2>
        <p>
          <strong>Event Planners</strong>: To create an event and generate QR codes, you must register for an account and subscribe to one of our subscription plans (Starter, Pro, or Enterprise). You are responsible for maintaining the confidentiality of your account credentials and for all activities that occur under your account.
          <br />
          <strong>Attendees</strong>: Attendees do not need to create an account to upload photos and videos. Media uploaded through the QR code will be accessible to the event planner.
        </p>
      </section>

      <section>
        <h2>4. Subscription Plans and Free Trial</h2>
        <p>
          Elevate Event Studio offers multiple subscription tiers:
          <ul>
            <li><strong>Essential Plan</strong>: Create and manage up to three events per month.</li>
            <li><strong>Advanced Plan</strong>: Create and manage up to 7 events per month.</li>
            <li><strong>Enterprise Plan</strong>: Create and manage an unlimited number of events.</li>
          </ul>
          We also offer a 30-day free trial for new users. After the trial period, your subscription will automatically renew unless canceled before the end of the trial.
        </p>
      </section>

      <section>
        <h2>5. User-Generated Content</h2>
        <p>
          <strong>Attendee Uploads</strong>: By uploading content through the QR code, attendees grant the event planner and Elevate Event Studio a non-exclusive, royalty-free license to store and use the media for the purposes of the event.
          <br />
          <strong>Content Responsibility</strong>: The event planner is responsible for ensuring that all uploaded content complies with relevant privacy and copyright laws. Elevate Event Studio reserves the right to remove any content that violates these terms or applicable laws.
        </p>
      </section>

      <section>
        <h2>6. Privacy and Data Collection</h2>
        <p>
          Elevate Event Studio collects personal information such as event planner registration details and media uploaded by event attendees. We are committed to protecting your privacy and will only use your data in accordance with our Privacy Policy. Attendees’ media uploads will be shared only with the event planner and not with third parties unless required by law.
        </p>
      </section>

      <section>
        <h2>7. Payment and Refund Policy</h2>
        <p>
          All payments are processed securely through our third-party payment provider, Stripe. By subscribing to one of our plans, you agree to pay the applicable subscription fees. Subscription fees are non-refundable, except in cases where required by law.
        </p>
      </section>

      <section>
        <h2>8. Termination</h2>
        <p>
          Elevate Event Studio reserves the right to terminate or suspend your account at any time for any reason, including violation of these Terms. Upon termination, your access to the service will cease immediately.
        </p>
      </section>

      <section>
        <h2>9. Prohibited Conduct</h2>
        <p>
          When using Elevate Event Studio, you agree not to:
          <ul>
            <li>Use the platform to distribute illegal or inappropriate content.</li>
            <li>Upload content that violates privacy, copyright, or other legal protections.</li>
            <li>Interfere with the security or integrity of the platform.</li>
            <li>Reverse engineer or attempt to extract the source code of the application.</li>
          </ul>
        </p>
      </section>

      <section>
        <h2>10. Limitation of Liability</h2>
        <p>
          To the fullest extent permitted by law, Elevate Event Studio shall not be liable for any indirect, incidental, special, or consequential damages resulting from the use of or inability to use the service. This includes, but is not limited to, loss of data, loss of profits, or damage to equipment.
        </p>
      </section>

      <section>
        <h2>11. Modifications to the Terms</h2>
        <p>
          Elevate Event Studio may revise these Terms at any time. When we make changes, we will post the updated terms on our website and update the "Last updated" date. By continuing to use our service after changes are posted, you agree to the new terms.
        </p>
      </section>

      <section>
        <h2>12. Governing Law</h2>
        <p>
          These Terms shall be governed by and construed in accordance with the laws of United States, without regard to its conflict of law principles.
        </p>
      </section>

      <section>
        <h2>13. Contact Us</h2>
        <p>
          If you have any questions or concerns regarding these Terms, you can contact us at markpelsone@elevateeventstudio.com.
        </p>
      </section>
    </div>
  );
};

export default TermsAndConditions;
