import React from 'react';
import { Typography, Box } from '@mui/material';

const QRCodeTemplates = () => {
  return (
    <Box 
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        flexDirection: 'column',
      }}
    >
      <Typography variant="h3" component="h1" sx={{ fontWeight: 'bold', marginBottom: '20px' }}>
        QR Code Templates
      </Typography>
      <Typography variant="h5" component="h2" color="textSecondary">
        Coming Soon
      </Typography>
    </Box>
  );
};

export default QRCodeTemplates;
