import React from 'react';
import { Typography, Box, Container } from '@mui/material';

const Docs = () => {
  return (
    <Container>
      <Box 
        sx={{
          padding: '40px 20px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        {/* Title */}
        <Typography variant="h3" component="h1" sx={{ fontWeight: 'bold', marginBottom: '30px' }}>
          Event Planner Features and User Guidelines
        </Typography>

        {/* Information for Event Planners */}
        <Typography variant="h5" component="h2" sx={{ fontWeight: 'bold', marginBottom: '20px' }}>
          Event Planner Controls
        </Typography>
        <Typography variant="body1" sx={{ marginBottom: '20px' }}>
          As an event planner, you have full control over the media uploaded by users during your event. This includes the ability to delete any photos or videos that are uploaded. Users, however, do not have this capability. This ensures that event planners maintain control over the gallery content, keeping the event's online presence clean and professional.
        </Typography>

        {/* Information for Users on Saving Media */}
        <Typography variant="h5" component="h2" sx={{ fontWeight: 'bold', marginBottom: '20px' }}>
          Saving Photos and Videos
        </Typography>
        <Typography variant="body1" sx={{ marginBottom: '20px' }}>
          Users can easily save photos and videos directly to their mobile devices. After uploading or viewing content in the event gallery, simply select the desired media and use your device's save functionality to download it. This ensures that memories from the event are preserved and easily accessible at any time.
        </Typography>

        {/* Information on Uploading Videos */}
        <Typography variant="h5" component="h2" sx={{ fontWeight: 'bold', marginBottom: '20px' }}>
          Video Upload Limits
        </Typography>
        <Typography variant="body1">
          Users can upload videos up to 10 seconds in length. This limit ensures that the platform maintains fast performance while allowing users to share highlights from the event. Event planners can monitor and manage all uploads to keep the gallery content aligned with their event’s theme and tone.
        </Typography>
      </Box>
    </Container>
  );
};

export default Docs;
