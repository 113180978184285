import React, { useState } from 'react';
import { Container, TextField, Typography, Button, Box, Grid, Paper } from '@mui/material';

const ROICalculator = () => {
  const [numberOfEvents, setNumberOfEvents] = useState(3);
  const [consumerAppCostPerEvent, setConsumerAppCostPerEvent] = useState(50);
  const [timeSpentManagingPhotos, setTimeSpentManagingPhotos] = useState(1);
  const [upsellRevenue, setUpsellRevenue] = useState(50);
  const [roi, setRoi] = useState(0);

  // Cost of using this app based on the number of events
  const appCost = numberOfEvents <= 3 ? 39.99 : 69.99;
  const hourlyRate = 50; // Value of time in $ per hour for managing photos

  const calculateROIGains = () => {
    // Total cost if using competitor's app
    const competitorCost = consumerAppCostPerEvent * numberOfEvents;
    
    // Calculating potential added revenue and time value saved
    const totalUpsellRevenue = upsellRevenue * numberOfEvents;
    const timeValueSaved = timeSpentManagingPhotos * hourlyRate * numberOfEvents;

    // Total gain calculation based on savings from competitor cost, added revenue, and time savings
    const totalGain = (competitorCost - appCost) + totalUpsellRevenue + timeValueSaved;
    
    // Calculating ROI based on the gain divided by the app cost
    const calculatedROI = (totalGain / appCost) * 100;

    setRoi(calculatedROI.toFixed(2));
  };

  return (
    <Container maxWidth="md" sx={{ padding: { xs: '20px', sm: '40px' } }}>
      {/* Standalone Title with Black Font */}
      <Typography variant="h4" gutterBottom sx={{ fontWeight: 'bold', textAlign: 'center', color: '#000', marginBottom: '30px', fontFamily: 'Didot, serif' }}>
        The Only Live QR Code Platform Designed for Event Planners
      </Typography>

      {/* ROI Calculator Section */}
      <Paper 
        elevation={4} 
        sx={{
          padding: { xs: '20px', sm: '40px', md: '50px' },
          textAlign: 'center',
          backgroundColor: '#1c1c1e',
          color: '#ffd700',
          borderRadius: '10px',
        }}
      >
        <Typography variant="h5" gutterBottom sx={{ fontWeight: 'bold', color: '#ffd700', fontSize: { xs: '1.5rem', sm: '1.8rem', md: '2.2rem' }, fontFamily: 'Didot, serif' }}>
          Event Planner ROI Calculator
        </Typography>
        <Typography variant="body1" sx={{ color: '#b0b0b0', marginBottom: '30px', fontSize: { xs: '0.9rem', sm: '1rem' }, fontFamily: 'Didot, serif' }}>
          Calculate your potential savings and additional revenue using our app.
        </Typography>

        {/* Input Fields Section */}
        <Box component="form" noValidate autoComplete="off" sx={{ mt: 3 }}>
          <Grid container spacing={{ xs: 2, sm: 3 }} alignItems="center">
            <Grid item xs={12} sm={6}>
              <Typography sx={{ color: '#ffd700', fontSize: '0.85rem', fontWeight: 'bold', marginBottom: '5px', textAlign: 'left', fontFamily: 'Didot, serif' }}>
                Enter the number of events you're managing per month
              </Typography>
              <TextField
                placeholder="Number of Events"
                type="number"
                fullWidth
                value={numberOfEvents}
                onChange={(e) => setNumberOfEvents(parseInt(e.target.value, 10))}
                InputProps={{ 
                  style: { 
                    color: '#000', 
                    backgroundColor: '#fff', 
                    fontSize: '0.85rem', 
                    padding: '10px 14px', 
                    height: '40px' 
                  } 
                }}
                sx={{ '& .MuiInputBase-root': { fontWeight: 'bold' } }}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <Typography sx={{ color: '#ffd700', fontSize: '0.85rem', fontWeight: 'bold', marginBottom: '5px', textAlign: 'left', fontFamily: 'Didot, serif' }}>
                Average cost per event with other photo apps
              </Typography>
              <TextField
                placeholder="Cost per Event"
                type="number"
                fullWidth
                value={consumerAppCostPerEvent}
                onChange={(e) => setConsumerAppCostPerEvent(parseFloat(e.target.value))}
                InputProps={{ 
                  style: { 
                    color: '#000', 
                    backgroundColor: '#fff', 
                    fontSize: '0.85rem', 
                    padding: '10px 14px', 
                    height: '40px' 
                  } 
                }}
                sx={{ '& .MuiInputBase-root': { fontWeight: 'bold' } }}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <Typography sx={{ color: '#ffd700', fontSize: '0.85rem', fontWeight: 'bold', marginBottom: '5px', textAlign: 'left', fontFamily: 'Didot, serif' }}>
                Approximate hours spent managing photos per event
              </Typography>
              <TextField
                placeholder="Hours Spent Managing Photos"
                type="number"
                fullWidth
                value={timeSpentManagingPhotos}
                onChange={(e) => setTimeSpentManagingPhotos(parseFloat(e.target.value))}
                InputProps={{ 
                  style: { 
                    color: '#000', 
                    backgroundColor: '#fff', 
                    fontSize: '0.85rem', 
                    padding: '10px 14px', 
                    height: '40px' 
                  } 
                }}
                sx={{ '& .MuiInputBase-root': { fontWeight: 'bold' } }}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <Typography sx={{ color: '#ffd700', fontSize: '0.85rem', fontWeight: 'bold', marginBottom: '5px', textAlign: 'left', fontFamily: 'Didot, serif' }}>
                Additional revenue from premium features
              </Typography>
              <TextField
                placeholder="Upsell Revenue per Event"
                type="number"
                fullWidth
                value={upsellRevenue}
                onChange={(e) => setUpsellRevenue(parseFloat(e.target.value))}
                InputProps={{ 
                  style: { 
                    color: '#000', 
                    backgroundColor: '#fff', 
                    fontSize: '0.85rem', 
                    padding: '10px 14px', 
                    height: '40px' 
                  } 
                }}
                sx={{ '& .MuiInputBase-root': { fontWeight: 'bold' } }}
              />
            </Grid>
          </Grid>

          {/* Calculate Button */}
          <Box mt={5} display="flex" justifyContent="center">
            <Button 
              variant="contained" 
              sx={{ 
                backgroundColor: '#000', 
                color: '#ffd700', 
                '&:hover': { backgroundColor: '#333' },
                fontWeight: 'bold',
                width: { xs: '100%', sm: '50%', md: '40%' },
                padding: '12px',
                fontSize: { xs: '0.9rem', sm: '1rem' },
              }}
              onClick={calculateROIGains}
            >
              Calculate ROI
            </Button>
          </Box>

          {/* ROI Result */}
          <Box mt={5}>
            <Typography variant="h5" gutterBottom sx={{ color: '#ffd700', fontSize: { xs: '1.5rem', sm: '1.8rem', md: '2rem' }, fontFamily: 'Didot, serif' }}>
              Your ROI: {roi}%
            </Typography>
            <Typography variant="body2" sx={{ color: '#b0b0b0', fontSize: { xs: '0.8rem', sm: '0.9rem' }, fontFamily: 'Didot, serif' }}>
              Based on current costs, time savings, and upsell potential.
            </Typography>
          </Box>

          {/* Key Explanation */}
          <Box mt={4} sx={{ textAlign: 'left', color: '#b0b0b0', fontSize: { xs: '0.75rem', sm: '0.8rem', md: '0.9rem' } }}>
            <Typography variant="h6" sx={{ color: '#ffd700', fontSize: '1rem', fontWeight: 'bold', fontFamily: 'Didot, serif' }}>
              Key:
            </Typography>
            <Typography sx={{ fontFamily: 'Didot, serif' }}>
              <strong>Competitor's Cost</strong>: Calculated as <em>consumerAppCostPerEvent * numberOfEvents</em>.
            </Typography>
            <Typography sx={{ fontFamily: 'Didot, serif' }}>
              <strong>Your App's Cost</strong>: $39.99 if managing up to 3 events, or $69.99 if more than 3 events.
            </Typography>
            <Typography sx={{ fontFamily: 'Didot, serif' }}>
              <strong>Total Gain Calculation</strong>: <em>totalGain = competitorCost - appCost + totalUpsellRevenue + timeValueSaved</em>.
            </Typography>
          </Box>

          {/* Disclaimer */}
          <Box mt={4}>
            <Typography variant="body2" sx={{ color: '#b0b0b0', fontSize: { xs: '0.75rem', sm: '0.8rem', md: '0.9rem' }, fontFamily: 'Didot, serif' }}>
              *Note: These estimates are guidelines. Actual results may vary based on client circumstances and market conditions.
            </Typography>
          </Box>
        </Box>
      </Paper>
    </Container>
  );
};

export default ROICalculator;
