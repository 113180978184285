import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App'; // Adjusted path for App
import { AuthProvider } from './firebase/auth'; // Import AuthProvider
import './index.css';   // Adjusted path for index.css

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <AuthProvider>
      <App />
    </AuthProvider>
  </React.StrictMode>
);
