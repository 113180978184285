import React from 'react';
import { Box, Typography } from '@mui/material';

const FeatureCard = ({ title, description, icon }) => {
  return (
    <Box
      sx={{
        padding: '20px',
        borderRadius: '8px',
        boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
        backgroundColor: 'white',
        textAlign: 'center',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: '100%', // Ensures that all cards have the same height
      }}
    >
      {/* Icon Container */}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: '80px',
          height: '80px',
          borderRadius: '50%',
          backgroundColor: '#f0f4ff',
          marginBottom: '20px',
        }}
      >
        {icon} {/* Icon passed from props */}
      </Box>
      <Typography variant="h5" sx={{ fontWeight: 'bold', marginBottom: '10px' }}>
        {title}
      </Typography>
      <Typography paragraph>
        {description}
      </Typography>
    </Box>
  );
};

export default FeatureCard;
