// fontsandthemes.js

export const fonts = [
  { name: 'Arial' },
  { name: 'Georgia' },
  { name: 'Comic Sans' },
  { name: 'Times New Roman' },
  { name: 'Courier New' },
  { name: 'Verdana' },
  { name: 'Tahoma' },
  { name: 'Trebuchet MS' },
  { name: 'Lucida Console' },
  { name: 'Garamond' },
  { name: 'Palatino Linotype' },
  { name: 'Impact' },
  { name: 'Franklin Gothic Medium' },
  { name: 'Gill Sans' },
  { name: 'Roboto' },
  { name: 'Lato' },
  { name: 'Montserrat' },
  { name: 'Open Sans' },
  { name: 'PT Sans' },
  { name: 'Raleway' }
];

export const themes = [
  { name: 'Minimalist', primaryColor: '#ffffff', secondaryColor: '#000000', backgroundColor: '#f9f9f9' },
  { name: 'Elegant', primaryColor: '#3b3b3b', secondaryColor: '#d4af37', backgroundColor: '#f7f3e9' },
  { name: 'Fun', primaryColor: '#ff4081', secondaryColor: '#03a9f4', backgroundColor: '#ffe4e1' },
  { name: 'Corporate', primaryColor: '#004080', secondaryColor: '#ff8000', backgroundColor: '#e6f2ff' },
  { name: 'Dark Mode', primaryColor: '#ffffff', secondaryColor: '#ff5722', backgroundColor: '#303030' },
  { name: 'Retro', primaryColor: '#ff6600', secondaryColor: '#009933', backgroundColor: '#f0e68c' },
  { name: 'Nature', primaryColor: '#2e8b57', secondaryColor: '#8fbc8f', backgroundColor: '#f5fffa' },
  { name: 'Pastel', primaryColor: '#ffb6c1', secondaryColor: '#d3ffce', backgroundColor: '#f0f8ff' },
  { name: 'Vibrant', primaryColor: '#ff0000', secondaryColor: '#ffff00', backgroundColor: '#fff5ee' },
  { name: 'Classic', primaryColor: '#00008b', secondaryColor: '#ffd700', backgroundColor: '#f8f8ff' }
];

// Updated Photo Grid Styles for a more creative presentation
export const photoGridStyles = [
  { name: 'Collage View', type: 'collage' },
  { name: 'Polaroid Style', type: 'polaroid' },
  { name: 'Masonry Grid', type: 'masonry' },
  { name: 'Diagonal Stack', type: 'diagonal' },
  { name: 'Overlay View', type: 'overlay' },
  { name: 'Dynamic Jigsaw', type: 'jigsaw' },
];
