import React from 'react';
import { Accordion, AccordionSummary, AccordionDetails, Typography, Box, Container } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const FAQ = () => {
  return (
    <Container maxWidth="md" sx={{ paddingY: 6, backgroundColor: '#f9f9f9', borderRadius: 3, boxShadow: '0 4px 20px rgba(0,0,0,0.1)' }}>
      {/* Page Title */}
      <Typography variant="h4" gutterBottom sx={{ fontWeight: 'bold', textAlign: 'center', marginBottom: 4, color: '#333' }}>
        Frequently Asked Questions
      </Typography>

      {/* Section: General Information */}
      <Box sx={{ marginBottom: 5 }}>
        <Typography variant="h5" gutterBottom sx={{ fontWeight: 'medium', color: '#555' }}>
          General Information
        </Typography>
        <Accordion sx={{ border: 'none', borderRadius: 3, boxShadow: '0 2px 15px rgba(0,0,0,0.1)', marginBottom: 2 }}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} sx={{ backgroundColor: '#ffffff', borderRadius: 3 }}>
            <Typography sx={{ fontWeight: 'bold', color: '#444' }}>What is Elevate Event Studio?</Typography>
          </AccordionSummary>
          <AccordionDetails sx={{ backgroundColor: '#fff', borderRadius: 3 }}>
            <Typography color="textSecondary">
              Elevate Event Studio is a platform designed for event planners to create events and instantly generate QR codes for attendees 
              to upload photos and videos to a digital gallery, streamlining the collection process.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion sx={{ border: 'none', borderRadius: 3, boxShadow: '0 2px 15px rgba(0,0,0,0.1)', marginBottom: 2 }}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} sx={{ backgroundColor: '#ffffff', borderRadius: 3 }}>
            <Typography sx={{ fontWeight: 'bold', color: '#444' }}>When is the QR code generated?</Typography>
          </AccordionSummary>
          <AccordionDetails sx={{ backgroundColor: '#fff', borderRadius: 3 }}>
            <Typography color="textSecondary">
              The QR code is generated instantly when you create an event, allowing you to use it immediately for sharing with attendees.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion sx={{ border: 'none', borderRadius: 3, boxShadow: '0 2px 15px rgba(0,0,0,0.1)', marginBottom: 2 }}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} sx={{ backgroundColor: '#ffffff', borderRadius: 3 }}>
            <Typography sx={{ fontWeight: 'bold', color: '#444' }}>Can I try Elevate Event Studio for free?</Typography>
          </AccordionSummary>
          <AccordionDetails sx={{ backgroundColor: '#fff', borderRadius: 3 }}>
            <Typography color="textSecondary">
              Yes, we offer a one-month free trial for event planners, giving you full access to create events, generate QR codes, and explore all features.
            </Typography>
          </AccordionDetails>
        </Accordion>
      </Box>

      {/* Section: Event Management and Customization */}
      <Box sx={{ marginBottom: 5 }}>
        <Typography variant="h5" gutterBottom sx={{ fontWeight: 'medium', color: '#555' }}>
          Event Management and Customization
        </Typography>
        <Accordion sx={{ border: 'none', borderRadius: 3, boxShadow: '0 2px 15px rgba(0,0,0,0.1)', marginBottom: 2 }}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} sx={{ backgroundColor: '#ffffff', borderRadius: 3 }}>
            <Typography sx={{ fontWeight: 'bold', color: '#444' }}>What can I customize when creating an event?</Typography>
          </AccordionSummary>
          <AccordionDetails sx={{ backgroundColor: '#fff', borderRadius: 3 }}>
            <Typography color="textSecondary">
              You can customize the event title, add a cover photo, and create a personalized welcome message, enhancing the experience for your attendees.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion sx={{ border: 'none', borderRadius: 3, boxShadow: '0 2px 15px rgba(0,0,0,0.1)', marginBottom: 2 }}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} sx={{ backgroundColor: '#ffffff', borderRadius: 3 }}>
            <Typography sx={{ fontWeight: 'bold', color: '#444' }}>Can I create posters or invitations with the QR code?</Typography>
          </AccordionSummary>
          <AccordionDetails sx={{ backgroundColor: '#fff', borderRadius: 3 }}>
            <Typography color="textSecondary">
              Yes, you can download the QR code and easily add it to promotional materials like posters or invitations, helping attendees easily access the event gallery.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion sx={{ border: 'none', borderRadius: 3, boxShadow: '0 2px 15px rgba(0,0,0,0.1)', marginBottom: 2 }}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} sx={{ backgroundColor: '#ffffff', borderRadius: 3 }}>
            <Typography sx={{ fontWeight: 'bold', color: '#444' }}>Can I share the QR code via email?</Typography>
          </AccordionSummary>
          <AccordionDetails sx={{ backgroundColor: '#fff', borderRadius: 3 }}>
            <Typography color="textSecondary">
              Absolutely. You can email the QR code and event URL to attendees, allowing them to easily access the gallery from any location.
            </Typography>
          </AccordionDetails>
        </Accordion>
      </Box>

      {/* Section: Media Collection and Sharing */}
      <Box>
        <Typography variant="h5" gutterBottom sx={{ fontWeight: 'medium', color: '#555' }}>
          Media Collection and Sharing
        </Typography>
        <Accordion sx={{ border: 'none', borderRadius: 3, boxShadow: '0 2px 15px rgba(0,0,0,0.1)', marginBottom: 2 }}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} sx={{ backgroundColor: '#ffffff', borderRadius: 3 }}>
            <Typography sx={{ fontWeight: 'bold', color: '#444' }}>Why not use Google Drive or Dropbox for photo collection?</Typography>
          </AccordionSummary>
          <AccordionDetails sx={{ backgroundColor: '#fff', borderRadius: 3 }}>
            <Typography color="textSecondary">
              These services are not ideal for event photo collection as they lack control over uploads and permissions, which can expose sensitive files or make management difficult. Scan Capture provides a controlled, event-specific solution.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion sx={{ border: 'none', borderRadius: 3, boxShadow: '0 2px 15px rgba(0,0,0,0.1)', marginBottom: 2 }}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} sx={{ backgroundColor: '#ffffff', borderRadius: 3 }}>
            <Typography sx={{ fontWeight: 'bold', color: '#444' }}>Why is email not a good option for collecting photos?</Typography>
          </AccordionSummary>
          <AccordionDetails sx={{ backgroundColor: '#fff', borderRadius: 3 }}>
            <Typography color="textSecondary">
              Email collection can be time-consuming and disorganized. Scan Capture simplifies the process with a centralized gallery for all media uploads, saving event planners significant time.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion sx={{ border: 'none', borderRadius: 3, boxShadow: '0 2px 15px rgba(0,0,0,0.1)', marginBottom: 2 }}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} sx={{ backgroundColor: '#ffffff', borderRadius: 3 }}>
            <Typography sx={{ fontWeight: 'bold', color: '#444' }}>Can attendees save photos and share them?</Typography>
          </AccordionSummary>
          <AccordionDetails sx={{ backgroundColor: '#fff', borderRadius: 3 }}>
            <Typography color="textSecondary">
              Yes, attendees can download and share their favorite photos directly from the gallery, making it easy for everyone to keep and distribute event memories.
            </Typography>
          </AccordionDetails>
        </Accordion>
      </Box>
    </Container>
  );
};

export default FAQ;
