import React, { useState } from 'react';
import { Container, TextField, Button, Typography, Box, Paper, Grid, Tooltip, IconButton, CircularProgress } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import { useAuth } from '../firebase/auth';
import { useNavigate } from 'react-router-dom';

const SignIn = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false); // Loading state for CircularProgress
  const { signIn } = useAuth();
  const navigate = useNavigate();

  const handleSignIn = async (e) => {
    e.preventDefault();
    setLoading(true); // Start loading
    try {
      await signIn(email, password);
      navigate('/eventdashboard'); // Redirect to EventDashboard after successful sign-in
    } catch (error) {
      alert('Error signing in: ' + error.message);
    } finally {
      setLoading(false); // Stop loading
    }
  };

  return (
    <Container maxWidth="sm">
      <Paper elevation={3} style={{ padding: '20px', marginTop: '50px' }}>
        <Box display="flex" flexDirection="column" alignItems="center">
          <Typography variant="h4" component="h1" gutterBottom>
            Sign In
          </Typography>
          <form onSubmit={handleSignIn} style={{ width: '100%' }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Tooltip title="Enter your registered email address" arrow>
                  <TextField
                    label="Email"
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    fullWidth
                    required
                    variant="outlined"
                    autoFocus // Auto-focus on the email field
                    InputProps={{
                      endAdornment: (
                        <IconButton>
                          <InfoIcon />
                        </IconButton>
                      ),
                    }}
                  />
                </Tooltip>
              </Grid>
              <Grid item xs={12}>
                <Tooltip title="Enter your password" arrow>
                  <TextField
                    label="Password"
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    fullWidth
                    required
                    variant="outlined"
                    InputLabelProps={{ shrink: true }} // Ensure label stays in place to prevent overlap
                    InputProps={{
                      endAdornment: (
                        <IconButton>
                          <InfoIcon />
                        </IconButton>
                      ),
                    }}
                  />
                </Tooltip>
              </Grid>
              <Grid item xs={12}>
                <Button
                  type="submit"
                  variant="contained"
                  fullWidth
                  disabled={loading} // Disable button when loading
                  sx={{ backgroundColor: '#000', color: '#fff', '&:hover': { backgroundColor: '#333' } }}
                >
                  {loading ? <CircularProgress size={24} sx={{ color: '#fff' }} /> : 'Sign In'}
                </Button>
              </Grid>
            </Grid>
          </form>
        </Box>
      </Paper>
    </Container>
  );
};

export default SignIn;
